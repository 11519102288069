@import "@toast-ui/editor/dist/toastui-editor.css";

/* height */
.auto-height,
.auto-height .toastui-editor-defaultUI {
  height: auto;
}

.auto-height .toastui-editor-md-container {
  position: relative;
}

:not(.auto-height) > .toastui-editor-defaultUI,
:not(.auto-height) > .toastui-editor-defaultUI > .toastui-editor-main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

:not(.auto-height) > .toastui-editor-defaultUI > .toastui-editor-main {
  -ms-flex: 1;
  flex: 1;
}

/* toastui editor */
.toastui-editor-md-container::after,
.toastui-editor-defaultUI-toolbar::after {
  content: '';
  display: block;
  height: 0;
  clear: both;
}


.toastui-editor-main {
  min-height: 0px;
  position: relative;
  height: inherit;
  box-sizing: border-box;
}

.toastui-editor-md-container {
  display: none;
  overflow: hidden;
  height: 100%;
}

.toastui-editor-md-container .toastui-editor {
  line-height: 1.5;
  position: relative;
}

.toastui-editor-md-container .toastui-editor,
.toastui-editor-md-container .toastui-editor-md-preview {
  box-sizing: border-box;
  padding: 0;
  height: inherit;
}

.toastui-editor-md-container .toastui-editor-md-preview {
  overflow: auto;
  padding: 0 25px;
  height: 100%;
}

.toastui-editor-md-container .toastui-editor-md-preview > p:first-child {
  margin-top: 0 !important;
}

.toastui-editor-md-container .toastui-editor-md-preview .toastui-editor-contents {
  padding-top: 8px;
}

.toastui-editor-main .toastui-editor-md-tab-style > .toastui-editor,
.toastui-editor-main .toastui-editor-md-tab-style > .toastui-editor-md-preview {
  width: 100%;
  display: none;
}

.toastui-editor-main .toastui-editor-md-tab-style > .active {
  display: block;
}

.toastui-editor-main .toastui-editor-md-vertical-style > .toastui-editor-tabs {
  display: none;
}

.toastui-editor-main .toastui-editor-md-tab-style > .toastui-editor-tabs {
  display: block;
}

.toastui-editor-main .toastui-editor-md-vertical-style .toastui-editor {
  width: 50%;
}

.toastui-editor-main .toastui-editor-md-vertical-style .toastui-editor-md-preview {
  width: 50%;
}

.toastui-editor-main .toastui-editor-md-splitter {
  display: none;
  height: 100%;
  width: 1px;
  background-color: #ebedf2;
  position: absolute;
  left: 50%;
}

.toastui-editor-main .toastui-editor-md-vertical-style .toastui-editor-md-splitter {
  display: block;
}

.toastui-editor-ww-container {
  display: none;
  overflow: hidden;
  height: inherit;
  background-color: #fff;
}

.auto-height .toastui-editor-main-container {
  position: relative;
}

.toastui-editor-main-container {
  position: absolute;
  line-height: 1;
  color: #222;
  width: 100%;
  height: inherit;
}

.toastui-editor-ww-container > .toastui-editor {
  height: inherit;
  position: relative;
  width: 100%;
}

.toastui-editor-ww-container .toastui-editor-contents {
  overflow: auto;
  box-sizing: border-box;
  margin: 0px;
  padding: 16px 25px 0px 25px;
  height: inherit;
}

.toastui-editor-ww-container .toastui-editor-contents p {
  margin: 0;
}

.toastui-editor-md-mode .toastui-editor-md-container,
.toastui-editor-ww-mode .toastui-editor-ww-container {
  display: block;
  z-index: 20;
}

.toastui-editor-md-mode .toastui-editor-md-vertical-style {
  display: -ms-flexbox;
  display: flex;
}

.toastui-editor-main.hidden,
.toastui-editor-defaultUI.hidden {
  display: none;
}

/* default UI Styles */
.toastui-editor-defaultUI .ProseMirror {
  padding: 8px 20px;
}

.toastui-editor-defaultUI {
  position: relative;
  border: 1px solid #dadde6;
  height: 100%;
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', '나눔바른고딕',
    'Nanum Barun Gothic', '맑은고딕', 'Malgun Gothic', sans-serif;
  border-radius: 4px;
}

.toastui-editor-defaultUI button {
  color: #333;
  height: 28px;
  font-size: 13px;
  cursor: pointer;
  border: none;
  border-radius: 2px;
}

.toastui-editor-defaultUI .toastui-editor-ok-button {
  min-width: 63px;
  height: 32px;
  background-color: #00a9ff;
  color: #fff;
  outline-color: #009bf2;
}

.toastui-editor-defaultUI .toastui-editor-ok-button:hover {
  background-color: #009bf2;
}

.toastui-editor-defaultUI .toastui-editor-close-button {
  min-width: 63px;
  height: 32px;
  background-color: #f7f9fc;
  border: 1px solid #dadde6;
  margin-right: 5px;
  outline-color: #cbcfdb;
}

.toastui-editor-defaultUI .toastui-editor-close-button:hover {
  border-color: #cbcfdb;
}

/* mode switch tab */
.toastui-editor-mode-switch {
  background-color: var(--bs-gray-200) !important;
  // background-color: #fff;
  // border-top: 1px solid #dadde6;
  font-size: 12px;
  text-align: right;
  height: 28px;
  padding-right: 0px !important;
  border-radius: 0 0 3px 3px;
  border-top: 1px solid #fff;
}

.toastui-editor-mode-switch .tab-item {
  background-color: var(--bs-gray-100) !important;
  display: inline-block;
  width: 96px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  color: #262729;
  margin-top: -1px;
  margin-right: -1px;
  cursor: pointer;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 0 0 4px 4px;
  font-weight: 500;
  box-sizing: border-box;
}

.toastui-editor-mode-switch .tab-item.active {
  color: var(--bs-gray-800) !important;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  font-size: 13px;
  font-weight: bold;
}

/* markdown tab */
.toastui-editor-defaultUI .toastui-editor-md-tab-container {
  float: left;
  height: 45px;
  font-size: 14px;
  background: #f7f9fc;
  border-bottom: 1px solid #ebedf2;
  border-top-left-radius: 3px;
}

.toastui-editor-md-tab-container .toastui-editor-tabs {
  margin-left: 15px;
  height: 100%;
}

.toastui-editor-md-tab-container .tab-item {
  display: inline-block;
  width: 70px;
  height: 33px;
  line-height: 33px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  background: #eaedf1;
  color: #969aa5;
  cursor: pointer;
  border: 1px solid #dadde6;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  margin-top: 13px;
}

.toastui-editor-md-tab-container .tab-item.active {
  border-bottom: 1px solid #fff;
  background-color: #fff;
  color: #555;
}

.toastui-editor-md-tab-container .tab-item:last-child {
  margin-left: -1px;
}

/* toolbar */
.toastui-editor-defaultUI-toolbar {
  display: -ms-flexbox;
  display: flex;
  padding: 0px 7px;
  height: 35px;
  background-color: #f7f9fc;
  border-bottom: 1px solid #ebedf2;
  border-radius: 3px 3px 0 0;
}

.toastui-editor-toolbar {
  height: 46px;
  box-sizing: border-box;
}

.toastui-editor-toolbar-divider {
  display: inline-block;
  width: 1px;
  height: 18px;
  background-color: #e1e3e9;
  margin: 10px 8px;
}

.toastui-editor-toolbar-group {
  display: -ms-flexbox;
  display: flex;
}

.toastui-editor-defaultUI-toolbar button {
  box-sizing: border-box;
  cursor: pointer;
  width: 25px;
  height: 25px;
  padding: 0;
  border-radius: 3px;
  margin: 5px 0px !important;
  border: none !important;
}

.toastui-editor-defaultUI-toolbar button:not(:disabled):hover {
  // border: 1px solid transparent !important;
  background-color: transparent !important;
}

.toastui-editor-defaultUI-toolbar .scroll-sync {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 10px;
  text-align: center;
  line-height: 10px;
  color: #81858f;
  cursor: pointer;
}

.toastui-editor-defaultUI-toolbar .scroll-sync::before {
  content: 'Scroll';
  position: absolute;
  left: 0;
  font-size: 14px;
}

.toastui-editor-defaultUI-toolbar .scroll-sync.active::before {
  color: #00a9ff;
}

.toastui-editor-defaultUI-toolbar .scroll-sync input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toastui-editor-defaultUI-toolbar .switch {
  position: absolute;
  top: 0;
  left: 45px;
  right: 0;
  bottom: 0;
  background-color: #d6d8de;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50px;
}

.toastui-editor-defaultUI-toolbar input:checked + .switch {
  background-color: #acddfa;
}
.toastui-editor-contents .task-list-item::before {
  top: 2px
}
.toastui-editor-defaultUI-toolbar .switch::before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 0px;
  bottom: -2px;
  background-color: #94979f;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

.toastui-editor-defaultUI-toolbar input:checked + .switch::before {
  background-color: #00a9ff;
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.toastui-editor-dropdown-toolbar .scroll-sync {
  margin: 0 5px;
}

.toastui-editor-dropdown-toolbar {
  position: absolute;
  height: 30px;
  z-index: 30;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dadde6;
  background-color: #f7f9fc;
  display: -ms-flexbox;
  display: flex;
}

.toastui-editor-toolbar-item-wrapper {
  margin: 7px 5px;
  height: 32px;
  line-height: 32px;
}

/* toolbar popup */
.toastui-editor-popup {
  width: 400px;
  margin-right: auto;
  background: #fff;
  z-index: 30;
  position: absolute;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dadde6;
}

.toastui-editor-popup-body {
  padding: 15px;
  font-size: 12px;
}

.toastui-editor-popup-body label {
  font-weight: 600;
  color: #555;
  display: block;
  margin: 20px 0 5px;
}

.toastui-editor-popup-body .toastui-editor-button-container {
  text-align: right;
  margin-top: 20px;
}

.toastui-editor-popup-body input[type='text'] {
  width: calc(100% - 26px);
  height: 30px;
  padding: 0 12px;
  border-radius: 2px;
  border: 1px solid #e1e3e9;
  color: #333;
}

.toastui-editor-popup-body input[type='text']:focus {
  outline: 1px solid #00a9ff;
  border-color: transparent;
}

.toastui-editor-popup-body input[type='text'].disabled {
  background-color: #f7f9fc;
  border-color: #e1e3e9;
  color: #969aa5;
}

.toastui-editor-popup-body input[type='file'] {
  opacity: 0;
  border: none;
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.toastui-editor-popup-body input.wrong,
.toastui-editor-popup-body span.wrong {
  border-color: #fa2828;
}

.toastui-editor-popup-add-link .toastui-editor-popup-body,
.toastui-editor-popup-add-image .toastui-editor-popup-body {
  padding: 0 20px 20px;
}

.toastui-editor-popup-add-image .toastui-editor-tabs {
  margin: 5px 0 10px;
}

.toastui-editor-popup-add-image .toastui-editor-tabs .tab-item {
  display: inline-block;
  width: 60px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #dadde6;
  color: #333;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
}

.toastui-editor-popup-add-image .toastui-editor-tabs .tab-item:hover {
  border-bottom: 1px solid #cbcfdb;
}

.toastui-editor-popup-add-image .toastui-editor-tabs .tab-item.active {
  color: #00a9ff;
  border-bottom: 2px solid #00a9ff;
}

.toastui-editor-popup-add-image .toastui-editor-file-name {
  width: 58%;
  display: inline-block;
  border-radius: 2px;
  border: 1px solid #e1e3e9;
  color: #dadde6;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.toastui-editor-popup-add-image .toastui-editor-file-name.has-file {
  color: #333;
}

.toastui-editor-popup-add-image .toastui-editor-file-select-button {
  width: 33%;
  margin-left: 5px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #dadde6;
  background-color: #f7f9fc;
  vertical-align: top;
}

.toastui-editor-popup-add-image .toastui-editor-file-select-button:hover {
  border-color: #cbcfdb;
}

.toastui-editor-popup-add-table {
  width: auto;
}

.toastui-editor-popup-add-table .toastui-editor-table-selection {
  position: relative;
}

.toastui-editor-popup-add-table .toastui-editor-table-cell {
  display: table-cell;
  width: 20px;
  height: 20px;
  border: 1px solid #e1e3e9;
  background: #fff;
  box-sizing: border-box;
}

.toastui-editor-popup-add-table .toastui-editor-table-cell.header {
  background: #f7f9fc;
}

.toastui-editor-popup-add-table .toastui-editor-table-row {
  display: table-row;
}

.toastui-editor-popup-add-table .toastui-editor-table {
  display: table;
  border-collapse: collapse;
}

.toastui-editor-popup-add-table .toastui-editor-table-selection-layer {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #00a9ff;
  background: rgba(0, 169, 255, 0.1);
  z-index: 30;
}

.toastui-editor-popup-add-table .toastui-editor-table-description {
  margin: 5px 0 0;
  text-align: center;
  color: #333
}

.toastui-editor-popup-add-heading {
  width: auto;
}

.toastui-editor-popup-add-heading .toastui-editor-popup-body {
  padding: 0;
}

.toastui-editor-popup-add-heading h1,
.toastui-editor-popup-add-heading h2,
.toastui-editor-popup-add-heading h3,
.toastui-editor-popup-add-heading h4,
.toastui-editor-popup-add-heading h5,
.toastui-editor-popup-add-heading h6,
.toastui-editor-popup-add-heading ul,
.toastui-editor-popup-add-heading p {
  padding: 0;
  margin: 0;
}

.toastui-editor-popup-add-heading ul {
  padding: 5px 0;
  list-style: none;
}

.toastui-editor-popup-add-heading ul li {
  padding: 4px 12px;
  cursor: pointer;
}

.toastui-editor-popup-add-heading ul li:hover {
  background-color: #dff4ff;
}

.toastui-editor-popup-add-heading h1 {
  font-size: 24px;
}

.toastui-editor-popup-add-heading h2 {
  font-size: 22px;
}

.toastui-editor-popup-add-heading h3 {
  font-size: 20px;
}

.toastui-editor-popup-add-heading h4 {
  font-size: 18px;
}

.toastui-editor-popup-add-heading h5 {
  font-size: 16px;
}

.toastui-editor-popup-add-heading h6 {
  font-size: 14px;
}

/* table context menu */
.toastui-editor-context-menu {
  position: absolute;
  width: auto;
  min-width: 197px;
  color: #333;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dadde6;
  z-index: 30;
  padding: 5px 0;
  background-color: #fff;
}

.toastui-editor-context-menu .menu-group {
  list-style: none;
  border-bottom: 1px solid #ebedf2;
  padding: 0;
  margin: 0;
  font-size: 13px;
}

.toastui-editor-context-menu .menu-group:last-child {
  border-bottom: none !important;
}

.toastui-editor-context-menu .menu-item {
  height: 32px;
  line-height: 32px;
  padding: 0 14px;
  cursor: pointer;
}

.toastui-editor-context-menu span {
  display: inline-block;
}

.toastui-editor-context-menu span::before {
  // background: url('../img/toastui-editor.png') no-repeat;
  background-size: 466px 146px;
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.toastui-editor-context-menu .add-row-up::before {
  background-position: 3px -104px;
}

.toastui-editor-context-menu .add-row-down::before {
  background-position: -19px -104px;
}

.toastui-editor-context-menu .remove-row::before {
  background-position: -41px -104px;
}

.toastui-editor-context-menu .add-column-left::before {
  background-position: -63px -104px;
}

.toastui-editor-context-menu .add-column-right::before {
  background-position: -85px -104px;
}

.toastui-editor-context-menu .remove-column::before {
  background-position: -111px -104px;
}

.toastui-editor-context-menu .align-column-left::before {
  background-position: -129px -104px;
}

.toastui-editor-context-menu .align-column-center::before {
  background-position: -151px -104px;
}

.toastui-editor-context-menu .align-column-right::before {
  background-position: -173px -104px;
}

.toastui-editor-context-menu .remove-table::before {
  background-position: -197px -104px;
}

.toastui-editor-context-menu .disabled span::before {
  opacity: 0.3;
}

.toastui-editor-context-menu li:not(.disabled):hover {
  background-color: #dff4ff;
}

.toastui-editor-context-menu li.disabled {
  color: #c9ccd5;
}

.toastui-editor-tooltip {
  position: absolute;
  background-color: #444;
  z-index: 40;
  padding: 4px 7px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', '나눔바른고딕',
    'Nanum Barun Gothic', '맑은고딕', 'Malgun Gothic', sans-serif;
}

.toastui-editor-tooltip .arrow {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #444;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -3px;
  left: 6px;
  z-index: -1;
}

.toastui-editor-toolbar-icons {
  color: var(--bs-gray-600) !important;
  border: none !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .toastui-editor-toolbar-icons,
  .toastui-editor-context-menu span::before {
    // background: url('../img/toastui-editor-2x.png') no-repeat;
    background-size: 466px 146px;
  }
}

.toastui-editor-toolbar-icons {
  background-position-y: 3px;
}

.toastui-editor-toolbar-icons:disabled {
  opacity: 0.3;
}

.toastui-editor-toolbar-icons.heading {
  background-position-x: 3px;
}

.toastui-editor-toolbar-icons.bold {
  background-position-x: -23px;
  visibility: hidden;
}

.toastui-editor-toolbar-icons.italic {
  background-position-x: -49px;
}

.toastui-editor-toolbar-icons.strike {
  background-position-x: -75px;
}

.toastui-editor-toolbar-icons.hrline {
  background-position-x: -101px;
}

.toastui-editor-toolbar-icons.quote {
  background-position-x: -127px;
}

.toastui-editor-toolbar-icons.bullet-list {
  background-position-x: -153px;
}

.toastui-editor-toolbar-icons.ordered-list {
  background-position-x: -179px;
}

.toastui-editor-toolbar-icons.task-list {
  background-position-x: -205px;
}

.toastui-editor-toolbar-icons.indent {
  background-position-x: -231px;
}

.toastui-editor-toolbar-icons.outdent {
  background-position-x: -257px;
}

.toastui-editor-toolbar-icons.table {
  background-position-x: -283px;
}

.toastui-editor-toolbar-icons.image {
  background-position-x: -309px;
}

.toastui-editor-toolbar-icons.link {
  background-position-x: -334px;
}

.toastui-editor-toolbar-icons.code {
  background-position-x: -361px;
}

.toastui-editor-toolbar-icons.codeblock {
  background-position-x: -388px;
}

.toastui-editor-toolbar-icons.more {
  background-position-x: -412px;
}

.toastui-editor-toolbar-icons.color {
  background-size: 23px 88px;
  background-position: 0px 3px;
}

.toastui-editor-toolbar-icons:not(:disabled).active {
  background-position-y: -23px;
}

.toastui-editor-contents {
  p,
  div,
  b,
  i,
  li {
    color: var(--bs-gray-800);
    margin: 0;
    font-size: 1.25rem;
  }
}

.toastui-editor-contents img {
  margin: 4px 0 10px;
  box-sizing: border-box;
  vertical-align: top;
  max-width: 30%;
}

.toastui-editor-contents h1 {
  border-bottom: 0px !important;
}

.toastui-editor-contents h2 {
  border-bottom: 0px !important;
}

.toastui-editor-contents:hover {
  background-color: var(--kt-gray-100) !important;
  * {
    background-color: var(--kt-gray-100) !important;
  }
}

.toastui-editor {
  position: absolute;
  line-height: 1;
  color: var(--bs-gray-800);
  width: 100%;
  height: inherit;
}

.toastui-editor-contents {
  margin: 0;
  padding: 0;
  max-width: 100%;
  white-space: initial;
  word-wrap: break-word;
  * {
    white-space: initial !important;
    word-wrap: break-word !important;
  }
}

@media only screen and (max-width: 480px) {
  .toastui-editor-popup {
    max-width: 300px;
    margin-left: -150px;
  }

  .toastui-editor-dropdown-toolbar {
    max-width: none;
  }
}