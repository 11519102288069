/* datepicker */
.ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
  width: 3rem !important;
  height: 3rem !important;
  padding-top: 7px;
  text-align: center;
  margin-left: 1px;
  margin-right: 1px;

  @extend .d-flex;
  @extend .flex-center;
}
.ngb-dp-day span:not(.range):not(.today):hover {
  background: var(--bs-gray-300);
  color: var(--bs-gray-800);
  border-radius: 100% !important;
  width: 3rem !important;
  height: 3rem !important;
  @extend .d-flex;
  @extend .flex-center;
}

.ngb-dp-day span {
  padding: 4px 8px;
  display: block;
}
.ngb-dp-day .range {
  background: var(--bs-info);
  color: #fff;
  border-radius: 100% !important;
  width: 3rem !important;
  height: 3rem !important;
  @extend .d-flex;
  @extend .flex-center;
}

.ngb-dp-weekday {
  color: black !important;
}
.ngb-dp-today {
  font-weight: bold;
}

.ngb-dp-weekday {
  font-style: normal !important;
  font-size: 13px;
}
ngb-datepicker-navigation-select > .form-select {
  flex: 1 1 auto;
  padding: 0 10px !important;
  font-size: 1rem !important;
  height: 2.3rem !important;
  margin-right: 5px !important;
}

datepicker:has(.delete) .ngb-dp-navigation-select {
  flex: 1 1 12rem !important;
  margin-right: 40px !important;
}

.ngb-dp-navigation-select {
  flex: 1 1 12rem !important;
  margin-right: -5px;
}

.ngb-dp-arrow {
  margin-top: -12px !important;
}

/* Ng select */
.ng-select .ng-value-label,
.ng-option,
.ng-option-disabled {
  font-size: 14px;
}

.menu-sub-dropdown,
.ng-dropdown-panel .ng-dropdown-panel-items {
  z-index: 1050;
}

.no-transition .ng-select > .ng-select-container {
  transition: none !important;
}

.cal-week-view,
.cal-month-view,
.vis-panel.vis-vertical {
  border-bottom-left-radius: 0.95rem !important;
  border-bottom-right-radius: 0.95rem !important;
//   box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%) !important;
}
.vis-panel.vis-vertical {
//   background-color: var(--bs-body-bg);
}

.vis-timeline .vis-panel.vis-top {
  background-color: var(--bs-app-light-sidebar-bg-color);
  top: 0 !important;
  box-shadow: 0 4px 7px -7px rgba(0, 0, 0, 0.25) !important;
  @extend .rounded-0;
}
table.pvtTable {
    box-shadow: none;
}
table.pvtTable th {
  background-color: var(--bs-secondary);
  border: 1px solid var(--bs-gray-300);
}
.remove-plotly-logo {
  border-radius: 0.95rem !important;
  box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%) !important;
  background-color: var(--bs-body-bg);
}
.pvtUi {
  width: 100%;
}
.pvtVals.pvtUiCell {
  width: 300px;
}

.ng-select > .ng-select-container {
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #f5f8fa !important;
  border-radius: 0.95rem !important;
  background: #f5f8fa !important;
  color: #716d66;
  transition: color 0.2s ease, background-color 0.2s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none !important;

  .ng-arrow-wrapper {
    display: none;
    justify-content: center;
    align-items: center;

    .ng-arrow {
      height: 8px;
      width: 8px;
    }
  }
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  height: auto !important;
  border-radius: 0.95rem !important;
  margin-top: 3px !important;
  margin-right: 4px !important;
  font-size: 0.9em !important;
  background-color: #fafafa !important;
  border: 1px solid #efe9e1 !important;
}

.ng-select .ng-clear-wrapper .ng-clear {
  left: 4px !important;
  bottom: 1px !important;
}

.ng-select .ng-clear-wrapper {
  display: none !important;
  width: 18px !important;
  height: 18px !important;
  right: -8px !important;
}

.ng-dropdown-panel {
  width: auto !important;
  background-color: transparent;
  z-index: 5 !important;
  font-weight: 500;

  .ng-dropdown-panel-items {
    @extend #{".dropdown-menu, .menu-sub, .menu-sub-dropdown, .menu-rounded"};
    width: 100%;
    padding: 10px;

    .ng-option {
      @extend #{".dropdown-item, .menu-link"};
      transition: none !important;
      -webkit-transition: none;
      border-radius: 0.95rem !important;

      &-label {
        font-size: 1.02rem !important;
        line-height: 1.4rem !important;
      }

      &-selected {
        @extend #{".dropdown-item, .active, .text-primary"};
        background-color: transparent !important;
      }
    }
  }
}

/* Ngx Toastr */
#toast-container > .toast-error {
  background-color: $danger;
}
#toast-container {
  font-size: 18px;
}
#toast-container > .toast-success {
  background-color: $success;
  font-weight: 500;
}

#toast-container > .toast-info {
  background-color: $primary;
}

/* Viewer.js */
.viewer-trash {
  color: #fff;
  font-size: 0.75rem;
  line-height: 1.75rem;
  text-align: center;
}

.viewer-trash::before {
  content: "\f2ed";
  font-family: "Font Awesome 6 Pro", serif;
  font-weight: 900;
}

.viewer-transition {
  -webkit-transition: all 0.1s !important;
  transition: all 0.1s !important;
}

/*** tui-editor ***/
@import "/src/app/components/editor/editor.component.scss";
@import "@toast-ui/editor/dist/toastui-editor.css";
@import 'tui-color-picker/dist/tui-color-picker.css';
@import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';



/*** Custom scroller style ***/

.scrollerbar {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f6f1e9;
  }

  &:hover {
    scrollbar-color: #f3ece2 transparent;

    &::-webkit-scrollbar-thumb {
      background-color: #f3ece2;
    }
  }
}
.horizental-scroll {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 1rem;
    height: 0.8rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f3f6f8;
    border-radius: 10px;
  }

  &:hover {
    scrollbar-color: #d3dde4 transparent;

    &::-webkit-scrollbar-thumb {
      background-color: #d3dde4;
      border-radius: 10px;
    }
  }
}

/*** Timeline style ***/

@media (max-width: 991.98px) {
  .vis-timeline .vis-panel.vis-top {
    top: 0 !important;
  }
}
.has-groupby .vis-group:empty:not([style="height: 51px;"]):not(:first-child) {
  border-top: 2px solid var(--bs-gray-200);
}
.one-liner-seperator {
  border-top: 1px solid var(--bs-gray-200);
}

.daily-mode .has-groupby .vis-group:empty:not([style="height: 51px;"]) {
  border-top: 2px solid darken($gray-200, 3%);
}

.has-groupby .vis-axis .vis-group {
  border-top: none !important;
}
.has-groupby .vis-panel.vis-background {
  width: calc(100% + 2px) !important;
//   left: 0 !important;
}
.vis-label.collapsed {
  opacity: 0.5;
}
.toastui-editor-contents img {
  border-radius: 0.95rem !important;
}

.progress-div {
  border-radius: 0.9rem !important;
  padding: 0.75rem 1rem;
  z-index: -99999;
}

.vis-timeline .vis-item {
  border-radius: 0.9rem !important;
}



.daily-mode .progress-div {
  border-radius: 0.5rem !important;
}

.daily-mode .vis-timeline .vis-item {
  border-radius: 0.5rem !important;
}

@import "custom";

@each $name, $value in $old-theme-colors {
  @if $name == "secondary" {
    [data-bs-theme="light"] {
      .progress-div-#{$name} {
        background-color: $value !important;
        box-shadow: inset 0 0 0 1px lighten($value, 7.5%) !important;
        color: white !important;
      }
    }
    [data-bs-theme="dark"] {
      .progress-div-#{$name} {
        background-color: $value !important;
        box-shadow: inset 0 0 0 1px darken($value, 7.5%) !important;
        color: white !important;
      }
    }
    .btn
      > .vis-item-overflow
      > .vis-item-content
      > div
      > .btn
      > .btn
      > .btn-#{$name} {
      background-color: darken($value, 15%) !important;
      box-shadow: inset 0 0 0 1px darken($value, 7.5%) !important;
      color: white !important;
    }
  } @else {
    [data-bs-theme="light"] {
      .progress-div-#{$name} {
        background-color: lighten($value, 25%) !important;
        box-shadow: inset 0 0 0 1px lighten($value, 7.5%) !important;
        color: white !important;
      }
    }
    [data-bs-theme="dark"] {
      .progress-div-#{$name} {
        background-color: darken($value, 25%) !important;
        box-shadow: inset 0 0 0 1px darken($value, 7.5%) !important;
        color: white !important;
      }
    }
  }

  .progress-div-#{$name}:hover {
    background-color: darken($value, 10%) !important;
    box-shadow: inset 0 0 0 1px darken($value, 7.5%) !important;
    color: white !important;
  }

  .btn:hover
    > .vis-item-overflow
    > .vis-item-content
    > div
    > .btn
    > .btn
    > .btn-#{$name} {
    background-color: darken($value, 20%) !important;
    box-shadow: inset 0 0 0 1px darken($value, 7.5%) !important;
    color: white !important;
  }
}
.ng-dropdown-panel {
  z-index: 1045 !important;
}
